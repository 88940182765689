// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  api: {
    root: "https://api.aliatour.oetfr.com/api"
  },
  fileserver: {
    images: "https://api.aliatour.oetfr.com/api/images"
  },
  google: {
    gmaps: {
      api_key: "xxx"
    }
  },
  auth: {
    providers: {
      google: { enabled: false }, // sign-in and register
      facebook: { enabled: false }, // sign-in and register
      email: {
        enabled: true,
        register: false,
        forgot: false,
        fields: {
          displayName: true,
          lastname: true,
          firstname: true,
          phone: true
        }
      },
      phone: { enabled: false }
    },
    roles: {
      whitelist: ['super-admin','client-admin'],
      blacklist: ['driver']
    },
    verifications: false

  },
  firebase: {
    apiKey: "AIzaSyDTxOWLxsgeJ4qUYlXxrqUBAU5WiVwOGXM",
    authDomain: "oet-aliatour.firebaseapp.com",
    databaseURL: "https://oet-aliatour.firebaseio.com",
    projectId: "oet-aliatour",
    storageBucket: "oet-aliatour.appspot.com",
    messagingSenderId: "367751314037",
    appId: "1:367751314037:web:1f902faf6aa15d425a563c",
    measurementId: "G-X1T1J2S7N9"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
